import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../layout';
import PostCardList from '../components/postCardLists/postCardList';
import SEO from '../components/SEO';
import SubscribeForm from '../components/subscribe/SubscribeForm';
import FeaturedPost from '../components/featuredPost';
import { HeaderSimple } from '../components/headers';

const PostListAlt = ({ data }) => {
  const { edges } = data.allMarkdownRemark;
  const postListAlt = [];
  edges.forEach(edge => {
    postListAlt.push({
      path: edge.node.fields.slug,
      tags: edge.node.frontmatter.tags,
      category: edge.node.frontmatter.category,
      cover: edge.node.frontmatter.cover,
      title: edge.node.frontmatter.title,
      date: edge.node.frontmatter.date,
      timeToRead: edge.node.timeToRead,
      excerpt: edge.node.excerpt,
    });
  });
  return (
    <Layout>
      <SEO />
      <div className="row py-4 m-0">
        {/* <FeaturedPost /> */}
        <div className="col-md-12 px-0 pb-4">
          <HeaderSimple title="Latest Articles" subTitle="Updated weekly" />
          <PostCardList posts={postListAlt} />
          <div className="col-md-12 p-4 text-center align-self-center p-4">
            <Link to="/blog" className="btn btn-outline-dark mx-auto text-center rounded-0 ">
              Read more &rarr;
            </Link>
          </div>
        </div>
        {/* <PostPagination currentPage={currentPage} numPages={numPages} /> */}
      </div>
      <hr />
      <div className="col-md-9 mx-auto p-0">
        <SubscribeForm />
      </div>
    </Layout>
  );
};

PostListAlt.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }).isRequired,
            excerpt: PropTypes.string.isRequired,
            timeToRead: PropTypes.number.isRequired,
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              tags: PropTypes.arrayOf(PropTypes.string),
              cover: PropTypes.object,
              category: PropTypes.string,
              date: PropTypes.string,
            }).isRequired,
          }).isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }).isRequired,
};

export default PostListAlt;

export const pageQuery = graphql`
  query PostListAltQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 6) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 100)
          timeToRead
          frontmatter {
            title
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 1920, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "MMMM DD YYYY")
            category
          }
        }
      }
    }
  }
`;
