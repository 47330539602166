import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const PostCardList = ({ posts }) => {
  const PostCard = loadable(() => import('../../postCards/postCard'));
  return (
    <>
      <div className="row m-0">
        <div className="col-md-8 align-self-center p-0 mx-auto">
          <div className="row justify-content-center m-0">
            {posts.map(post => (
              // eslint-disable-next-line react/jsx-key
              <div className="col-md-4 p-2">
                <PostCard data={post} key={post.title} />
              </div>
            ))}
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

PostCardList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      cover: PropTypes.object,
      title: PropTypes.string,
      category: PropTypes.string,
      date: PropTypes.string,
      timeToRead: PropTypes.number,
      excerpt: PropTypes.string,
    }),
  ).isRequired,
};

export default React.memo(PostCardList);
